.container-fluid {
  background-color: #f4fcff;
}
.Navbar_Icons a {
  font-size: 20px;
  letter-spacing: 6px;
  color: #0e0d13;
  font-weight: bold;
}
.Logo_Txt .Logo_txt1 {
  font-size: 20px;
  letter-spacing: 6px;
  font-weight: bold;
  color: #346b88;
}

.Logo_Txt .Logo_txt2 {
  font-size: 10px;
  color: #cc3e2e;
}
/* .Navbar_Items {
  display: contents;
} */
.Navbar_Items ul li a {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.navbar-collapse {
  text-align: left !important;
}
.Navbar_btn {
  justify-content: center;
  display: flex;
}
.btn-link a {
  background: #cc3e2f;
}
.btn-link {
  text-decoration: none;
}
.Home_Navbar {
  background-color: #f0fbff !important;
  border-radius: 10px;
  box-shadow: 0px 0px 4px 0px #a7a0a0;
}
.Home_Navbar a {
  font-size: 15px;
  font-weight: bold;
  color: black !important;
  border-radius: 5px;
  padding: 10px;
}
.Home_Navbar a:hover {
  background-color: #cc3e2f !important;
  color: #ffff !important;
}
.dropdown-menu.show {
  border-radius: 14px;
  border: 2px solid #e6e6e7;
}
.main-logo img {
  width: 200px;
}

/* Media css start */
@media only screen and (max-width: 1450px) and (min-width: 1201px) {
  /* .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0;
  } */
  a.btn-onboarding-public {
    padding: 11px 14px !important;
  }
  /* .navbar-expand-lg {
    align-items: flex-start !important;
  } */
}

@media only screen and (max-width: 1200px) {
  /* .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0 !important;
  } */

  /* .navbar-expand-lg {
    align-items: flex-start !important;
  } */
  a.btn-onboarding-public {
    padding: 8px 6px !important;
  }
  .Navbar_btn {
    justify-content: flex-start !important;
  }
  .navbar-collapse ul li a {
    font-size: 12px;
  }
  .main-logo svg {
    width: 200px;
  }
  .dropdown-toggle::after {
    margin-right: 5px;
  }
}

/* @media only screen and (max-width: 1100px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0 !important;
  }
} */
@media only screen and (max-width: 768px) {
  .main-logo svg {
    width: 245px;
  }
  .Navbar_btn {
    margin-top: 15px;
  }
  .Navbar_Items ul li a {
    line-height: 50px;
  }
  .Navbar_Items {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 400px) {
  .About_btn a.btn-onboarding-public {
    margin: 10px 10px !important;
    width: 180px;
  }
}

.About_btn a.btn-onboarding-public {
  margin-left: 10px !important;
}

/* Media css end */
