p {
  color: #787d7f;
  margin: 0%;
}
label {
  margin-bottom: 0%;
  font-size: 14px;
}
.form-control {
  font-size: 14px;
  border-radius: 10px;
}
.font-icons .font {
  width: 48px;
  height: 48px;
  border: 2px solid #cc3e2f;
  border-radius: 50%;
}
.font-icons .font svg {
  color: #cc3e2f;
  width: 29px;
  height: 29px;
  font-size: 24px;
  margin: 6px 8px;
}
.font_txt {
  padding-left: 10px;
}

@media only screen and (max-width: 756px) {
  .Contact_body h1 {
    font-size: 35px;
  }
  .font-icons {
    margin-top: 10px;
  }
  .Form_btn {
    text-align: center;
  }
  .Contact_Box {
    margin: 1rem 0 !important;
  }
}

.Contact_Text textarea {
  resize: none;
}
