.Otp_verify {
  background-image: url('../images/VerifyBackground.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Otp_verify .Otp_Body {
  width: 480px;
}
.Otp_verify .Otp_Txt {
  background-color: #fff;
  display: block;
  margin: auto;
  width: 100%;
  max-width: 95%;
  text-align: center;
  padding: 50px;
  border-radius: 5px;
}
.Otp_verify .Otp_Txt h1 {
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
}
.Otp_verify .Otp_Txt h6 {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 30px 0px;
}
.Otp_verify .Otp_Txt h5 {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  padding: 30px 0;
}
.Otp_verify .Otp_Txt span {
  color: #cc3e2f;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
}
.Otp_verify .Otp_Txt a:hover {
  color: #cc3e2f;
}
.Otp_verify .Otp_Txt button {
  background-color: #cc3e2f;
  color: #fff;
  font-size: 14px;
  line-height: 21px;
  border-radius: 40px;
}

.Otp_Txt .Otp_Box {
  margin: auto;
}

.Otp_Txt .Otp_Box .Otp_BodyBox:focus-visible {
  outline: none;
}

.Otp_Txt .Otp_Box .Otp_BodyBox {
  width: 40px;
  height: 40px;
  border: 2px solid #E6E6E7;
  color: #e2ebf1;
  margin: 5px;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
}

@media only screen and (max-width: 756px) {
  .Otp_Txt .Otp_Box .Otp_BodyBox {
    width: 30px;
    height: 30px;
    text-align: center;
  }
  .Otp_verify .Otp_Txt {
    padding: 20px 20px;
  }
}
